/*------------------------------------*\
    BASE
\*------------------------------------*/

html { 
	font-size: 62.5%;
	min-height: 100%;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	font-family: 'proxima-nova', sans-serif;
	@include font-size(1.6);
	color: $color--grey-dark;
	background-color: $color--grey-light;
}

.flex {
	display: block;

	@include breakpoint(xs) {
		&.flex-xs {
			display: flex;           
			flex-wrap: wrap;         
			align-items: stretch;        
			height: 100%;
		}
	}
			
	@include breakpoint(sm) {
		display: flex;           
		flex-wrap: wrap;         
		align-items: stretch;        
		height: 100%;
	}

	&.flex-center {
		justify-content: center;
	}
	
}


.page {
	margin-top: 60px;

	@include breakpoint(sm) {
		margin-top: 0;
	}
}

.padded-page-inner {
	padding: 30px;
	height: auto;
	overflow: auto;

	@include breakpoint(sm) {
		padding: 60px;
	}

	@include breakpoint(md) {
		padding: 60px 90px;
	}

	@include breakpoint(lg) {
		padding: 60px 100px;
	}
}

.container-fluid {
	max-width: 1400px
}

section {
	padding: 60px 0;

	@include breakpoint(sm) {
		padding: 100px 0;
	}

	&.section--slim {
		padding: 70px 0
	}

	&.section--ultra-slim {
		padding: 40px 0
	}

	&.section--no-padding {
		padding: 0;
	}

	&.section--zero-top {
		padding-top: 0;
	}
}

.section-title {
	color: $color--blue-light;
}

.contained-centred {

	img {
		width: 100%;
		height: auto;
	}

	h2 {
		color: $color--blue-light;

		@include breakpoint(md) {
			max-width: 70%;
			margin: 50px auto 15px;  

			&:first-child {
				margin-top: 0;
			}
		}
	}


	p {
		@include breakpoint(md) {
			max-width: 80%;
			margin: 0 auto 15px;
		}
	}
}


.embed-container { 
	position: relative; 
	padding-bottom: 56.25%; 
	overflow: hidden; 
	max-width: 100%; 

	iframe,
	object,
	embed {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
	}
} 

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0
	}

	100% {
		opacity: 1;
	}
}

.modal {
	position: fixed;
    padding: 0px;
    background-color: rgba(000,000,000,.8);
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 100;
    top: 0;
    display: none;

}

.modal__inner {
	max-width: 700px;
	max-height: 394px;
	margin: auto;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	position: fixed;
	z-index: 200;
}

.modal__close {
	position: absolute;
    top: -50px;
    right: 0;
    background: white;
    padding: 16px;
    height: 50px;
  	opacity: 1;
	animation-name: fadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 2s;
}

.fourohfour {
	padding-top: 80px;
	padding-bottom: 80px;
	text-align: center;
}