.hero {
	position: relative;
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	width: 100%;
	// min-height: 200px;
	text-align: center;

	&:before {
		height: 100%;
		width: 100%;
		background-color: rgba(000,000,000, 0.4);
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
	}

	h1 {
		color: white;
	}

	.hero-inner {
		position: relative;
		z-index: 5;
	}

	.btn {
		margin-top: 30px;
	}

	@include breakpoint(sm) {
	   min-height: 300px;
	}

	@include breakpoint(md) {
	   padding: 200px 0;
	}

}


.hero--slim {
	position: relative;
	min-height: 0;
	padding: 30px 0;

	h1 {
		color: $color--grey-dark;
		margin: 0
	}
	
	&:before {
		display: none
	}
}
