.ctas {
	
}

.cta {
	text-align: center;
	padding: 50px 0;
	background-size: cover;
	position: relative;
	overflow: hidden;

	&:hover {
		.cta__image {
			transform: scale(1.2) rotate(3deg);
		}
		
	}

	@include breakpoint(md) {
		padding: 80px 0;
	}
}

.cta__image {
	background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: transform ease .4s;
}

.cta__title {
	color: white;
	@include font-size(3.6);
	position: relative;
	z-index: 10;
}


.cta__btn {
	margin-top: 20px;
	clear: left;
	display: inline-block;
	clear: left;
	position: relative;
	z-index: 10;
}