/*------------------------------------*\
    CLASS PAGE FOOTER
\*------------------------------------*/

.class-footer-block {
	text-align: center;
	padding: 20px;
	@include font-size(1.8);

	@include breakpoint(md) {
		padding: 20px 80px;
	}

	* {
		color: $color--grey-dark;
	}

	h3 {
		@include font-size(3);
		color: $color--blue-light;
		margin-bottom: 20px;
	}

	p, a {
		font-weight: 600;

		.normal-weight {
			font-weight: 300;
		}
	}


	li {
		margin-bottom: 6px;
	}
}


/*------------------------------------*\
    PRE FOOTER
\*------------------------------------*/

.pre-footer {
	background-size: cover;
	background-position: 50% 50%;
	min-height: 300px;
	clear: both;

	@include breakpoint(md) {
		min-height: 500px
	}

	@include breakpoint(lg) {
		min-height: 650px
	}

	@include breakpoint(xl) {
		min-height: 800px
	}
}

.pre-footer-cta {
	background-color: $color--blue-dark;
	padding: 20px;
	text-align: center;
	margin-bottom: 40px;

	@include breakpoint(sm) {
		padding: 30px 20px;
	}

	@include breakpoint(md) {
		padding: 30px 30px;
	}

	@include breakpoint(lg) { 
		min-height: 170px;
		padding: 30px;
	}
}

.pre-footer-cta__title {
	color: white;
	@include font-size(2);

	@include breakpoint(sm) {
		@include font-size(3);
	}
}

.pre-footer-cta__btn {
	margin-top: 10px;
}

.mc-signup-form {
	padding: 0;
	max-width: 380px;
	margin: 15px auto 0;

	input[type=submit] {
		border-radius:  0 25px 25px 0;
		border: none;
		padding: 10px 20px;
		display: inline-block;
		margin-left: -5px;
		background-color: $color--blue-light;
		border: 2px solid $color--blue-light;
		color: white;
		height: 44px;

		@include breakpoint(md) {
			padding: 10px 30px;
		}

		&:hover {

		}
	}

	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: $color--grey-mid;

	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: $color--grey-mid;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: $color--grey-mid;
	}
}

/*------------------------------------*\
    FOOTER
\*------------------------------------*/

footer {
	background-color: $color--blue-dark;
	padding: 60px 0;

	* {
		color: white !important;
	}
}

.footer-top,
.footer-bottom {
	height: auto;
	// overflow: auto; 
	text-align: center;

	@include breakpoint(md) {
		text-align: left;
	}
}

.footer-logo {
	max-width: 200px;
	display: block;
	margin: 0 auto 40px;

	@include breakpoint(md) {
		margin:  0
	}
}

.footer-bottom {
	line-height: 1.5;
	margin-top: 60px;
	clear: left;

	* {
		font-weight: 300;
		@include font-size(1.4);
	}

	a {
		color: $color--blue-dark;
		text-decoration: none;

		&:hover {
			text-decoration: underline
		}
	}
}

.footer-image {
	display: block;
	margin: 20px auto;

	@include breakpoint(md) {
		margin: 0 0 20px;
	}
}

.footer__mfc-logo {
	max-width: 90px;
}

.footer__yoga-logo,
.footer__body-control-logo {
	max-width: 120px;
}

.footer-social {
	li {
		display: inline-block;
		margin: 0 10px;
	}

	a {
		width: 25px;
		height: 25px;
		display: block;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		
		svg {
			max-width: 100%;
			max-height: 100%;
		}

		* {
			transition: all ease .3s;
		}

		&:hover {
			path, circle {
				fill: $color--blue-lightest
			}
		}
	}

}

.overlay {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	background-color: rgba(000,000,000, .6);
	z-index: 50;
	display: block;
	top: 0;
	left: 0;
	position: fixed;
	opacity: 0;
	transition: opacity ease .4s;

	&.is-loaded {
		opacity: 1
	}
}

.popup {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	z-index: 100;
	max-width: 500px;
	max-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-200%);
	transition: transform ease .5s;

	&.is-loaded {
		transform: translateY(0);
	}
}

.popup__close {
	position: absolute;
	background-color: $color--blue-light;
	padding: 10px 15px;
	color: white;
	top: 0;
	right: 0;
	cursor: pointer;
	height: 40px;
	z-index: 120
}

.popup__content{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
	}
}