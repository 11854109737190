
.posts {
	list-style: none;
	padding-top: 30px;
	clear: left;
	height: auto;
	overflow: auto;
   
	li {
		margin-bottom: 30px;
		
		@media (min-width: breakpoint--large-tablet) {
			// margin-bottom: 0
		}
	}
}

.post {
	// margin: 20px 0;
}

.post-preview {
	background-color: $color--grey-light;
	position: relative;
	height: 100%;

	&:hover {
		.post-preview__thumbnail {
			transform: scale(1.2);
		}
	}

	.btn {
		margin-top: 14px;
	}
}

section:not(.homepage-blogs) {
	.post-preview, 
	.post-preview__text {
		background-color: white;
	}
}

.post-preview__thumbnail-wrapper {
	position: relative;
	display: block;
	text-decoration: none;
	background-color: $color--grey-dark;
	overflow: hidden;
	
}

.post-preview__thumbnail {
	text-decoration: none;
	position: relative;
	transition: transform ease .4s;
}

.post-preview__title {
	margin-bottom: 0;
	
	a {
		text-decoration: none;
		color: $color--blue-dark
	}
}

.post-preview__text {
	padding: 25px;
	background-color: $color--grey-light;
}



.post-preview__thumbnail {
	height: 200px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	
	@include breakpoint(md) {
		height: 200px;
	}
}


.post-preview__date {
    @include font-size(1.4);
	color: $color--blue-light;
	font-weight: 600;
}

.post-preview__title {
	@include font-size(2.6);
	margin-bottom: 20px;
}


.post-preview__link {
	font-weight: 400;
	text-decoration: underline;
}



.pagination {
	margin: 0 auto;
	position: relative;

	.current,
	.page-numbers,
	a {
		width: 21px;
		height: 21px;
		display: block;
		float: left;
		text-decoration: none;
		text-align: center;
		margin: 0 2px;
		padding-top: 4px;
		@include font-size(1.2)
	}

	.current {

		color: white;
	}

	a {
		background-color: white;

	}
}


// Single page

.single-post__featured-image {
	height: 200px;

	@include breakpoint(md) {
		height: 400px;
	}
}

.single-post__header {
	padding-bottom: 30px;
}

.single-post__content {
	padding: 30px 0 50px;
}

.single-post__date {
	color: $color--blue-light;
	@include font-size(1.5);
	font-weight: 500;
}