.team-profiles {
	padding: 0;
}

.team-member-profile {
	padding: 20px;
	margin-bottom: 30px;
	background: white;
	height: auto;
	overflow: auto;

	@include breakpoint(sm) {
		padding: 40px;
	}
}

.team-member-profile__image {
	width: 100%;
	margin-bottom: 30px;
	display: block;

	@include breakpoint(xs) {
		width: 200px;
	}
	
	@include breakpoint(sm) {
		float: left;
		width: 150px;
	}

	@include breakpoint(md) {
		width: 250px;
		margin-bottom: 0;
	}
}

.team-member-profile__content-wrapper {

	@include breakpoint(sm) {
		padding-left: 180px;
	}

	@include breakpoint(md) {
		padding-left: 280px;
	}
}

.team-member-profile__name {
	margin-bottom: 5px;
	color: $color--blue-light;
	
}

.team-member-profile__job {
	font-weight: 500;
	margin-top: 0;
	@include font-size(1.5);
	margin-bottom: 15px;

	span {
		position: relative;

		&:after {
			content: '|';
			position: relative;
			margin: 0 10px;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}
}

.team-member-profile__biog {
	@include font-size(1.4);

	blockquote {
		color: $color--blue-light;
		border: none;
		padding: 0;
		margin: 0;
		font-style: italic;
		margin-top: 20px;
	}
}
