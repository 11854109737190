.text-with-link {

    .btn {
        margin-top: 20px;
    }
	
	.section-text-wrapper {
		margin-top: 20px;
	}
	
}