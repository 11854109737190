/*------------------------------------*\
    HELPER
\*------------------------------------*/

// helper classes

.debug {
    border: 1px solid #f0f;
}

.debug2 {
    border: 1px solid lime;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clear { clear: both; }
.clear-left { clear: left!important; }
.clear-right { clear: right!important; }
.clear-none { clear: none!important; }

.float-left  { float: left; }

.float-right { float: right; }


.float-none  { float:none !important; }

.text-left   { text-align: left!important; }
.text-center { text-align: center!important; }
.text-right  { text-align: right!important; }

.width-full { width: 100%!important; }
.width-auto { width: auto!important; }


.display-table-wrapper {
    @include breakpoint(sm) {
        margin: 0 -20px;
        padding: 0;
    }
}

.display-table {
    @include breakpoint(sm) {
        width: 100%;
        display: table;
        border-spacing: 20px 0;
        position: relative;
    }
}

.display-table-cell {
    position: relative;
    vertical-align: top;
    width: 100%;
    display: block;
    overflow: hidden;
    clear: both;

    @include breakpoint(sm) {
        display: table-cell!important;
        border-collapse: separate;
    }
}


// responsive image
.img-responsive {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
}

.invisible {
  visibility: hidden;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.text-align-centre {
    text-align: center
}

.bg--white {
    background-color: white;
}

.bg--grey {
    background-color: $color--grey-dark;

    * {
        color: white
    }
}

.bg--light-grey {
    background-color: $color--grey-light;
}

.bg--midnight {
    background-color: $color--blue-dark;

    * {
        color: white
    }
}

.bg-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.color--blue {
    color: $color--blue-light
}
// modifying margin classes - remove if not used

// .margin-0  { margin: 0!important; }
// .margin-5  { margin: 5px!important; }
// .margin-10 { margin: 10px!important; }
// .margin-15 { margin: 15px!important; }
// .margin-20 { margin: 20px!important; }
// .margin-25 { margin: 25px!important; }
// .margin-30 { margin: 30px!important; }
// .margin-40 { margin: 40px!important; }
// .margin-50 { margin: 50px!important; }

// .margin-left-0  { margin-left: 0!important; }
// .margin-left-5  { margin-left: 5px!important; }
// .margin-left-10 { margin-left: 10px!important; }
// .margin-left-15 { margin-left: 15px!important; }
// .margin-left-20 { margin-left: 20px!important; }
// .margin-left-25 { margin-left: 25px!important; }
// .margin-left-30 { margin-left: 30px!important; }
// .margin-left-40 { margin-left: 40px!important; }
// .margin-left-50 { margin-left: 50px!important; }

// .margin-right-0  { margin-right: 0!important; }
// .margin-right-5  { margin-right: 5px!important; }
// .margin-right-10 { margin-right: 10px!important; }
// .margin-right-15 { margin-right: 15px!important; }
// .margin-right-20 { margin-right: 20px!important; }
// .margin-right-25 { margin-right: 25px!important; }
// .margin-right-30 { margin-right: 30px!important; }
// .margin-right-40 { margin-right: 40px!important; }
// .margin-right-50 { margin-right: 50px!important; }

// .margin-top-0  { margin-top: 0!important; }
// .margin-top-5  { margin-top: 5px!important; }
// .margin-top-10 { margin-top: 10px!important; }
// .margin-top-15 { margin-top: 15px!important; }
// .margin-top-20 { margin-top: 20px!important; }
// .margin-top-25 { margin-top: 25px!important; }
// .margin-top-30 { margin-top: 30px!important; }
.margin-top-40 { margin-top: 40px!important; }
.margin-top-50 { margin-top: 50px!important; }

// .margin-bottom-0  { margin-bottom: 0!important; }
// .margin-bottom-5  { margin-bottom: 5px!important; }
// .margin-bottom-10 { margin-bottom: 10px!important; }
// .margin-bottom-15 { margin-bottom: 15px!important; }
// .margin-bottom-20 { margin-bottom: 20px!important; }
// .margin-bottom-25 { margin-bottom: 25px!important; }
// .margin-bottom-30 { margin-bottom: 30px!important; }
.margin-bottom-40 { margin-bottom: 40px!important; }
.margin-bottom-50 { margin-bottom: 50px!important; }

// // modifying padding classes - remove if not

// .padding-0  { padding: 0!important; }
// .padding-5  { padding: 5px!important; }
// .padding-10 { padding: 10px!important; }
// .padding-15 { padding: 15px!important; }
// .padding-20 { padding: 20px!important; }
// .padding-25 { padding: 25px!important; }
// .padding-30 { padding: 30px!important; }
// .padding-40 { padding: 40px!important; }
// .padding-50 { padding: 50px!important; }

// .padding-left-0  { padding-left: 0!important; }
// .padding-left-5  { padding-left: 5px!important; }
// .padding-left-10 { padding-left: 10px!important; }
// .padding-left-15 { padding-left: 15px!important; }
// .padding-left-20 { padding-left: 20px!important; }
// .padding-left-25 { padding-left: 25px!important; }
// .padding-left-30 { padding-left: 30px!important; }
// .padding-left-40 { padding-left: 40px!important; }
// .padding-left-50 { padding-left: 50px!important; }

// .padding-right-0  { padding-right: 0!important; }
// .padding-right-5  { padding-right: 5px!important; }
// .padding-right-10 { padding-right: 10px!important; }
// .padding-right-15 { padding-right: 15px!important; }
// .padding-right-20 { padding-right: 20px!important; }
// .padding-right-25 { padding-right: 25px!important; }
// .padding-right-30 { padding-right: 30px!important; }
// .padding-right-40 { padding-right: 40px!important; }
// .padding-right-50 { padding-right: 50px!important; }

// .padding-top-0  { padding-top: 0!important; }
// .padding-top-5  { padding-top: 5px!important; }
// .padding-top-10 { padding-top: 10px!important; }
// .padding-top-15 { padding-top: 15px!important; }
// .padding-top-20 { padding-top: 20px!important; }
// .padding-top-25 { padding-top: 25px!important; }
// .padding-top-30 { padding-top: 30px!important; }
// .padding-top-40 { padding-top: 40px!important; }
// .padding-top-50 { padding-top: 50px!important; }

// .padding-bottom-0  { padding-bottom: 0!important; }
// .padding-bottom-5  { padding-bottom: 5px!important; }
// .padding-bottom-10 { padding-bottom: 10px!important; }
// .padding-bottom-15 { padding-bottom: 15px!important; }
// .padding-bottom-20 { padding-bottom: 20px!important; }
// .padding-bottom-25 { padding-bottom: 25px!important; }
// .padding-bottom-30 { padding-bottom: 30px!important; }
// .padding-bottom-40 { padding-bottom: 40px!important; }
// .padding-bottom-50 { padding-bottom: 50px!important; }



 