/*------------------------------------*\
	TYPOGRAPHY
\*------------------------------------*/

// Generic heading styling

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	margin: 0;
	padding: 0;

}

// Custom heading styling
// Markup should be semantically correct
// Use an appropriate class to visually style an h3 as an h2 - eg <h3 class="h2">

h1 {
	@include font-size(3);
	margin-bottom: 10px;
	font-weight: 100;

	@include breakpoint(md) {
		@include font-size(4.2)
	}
}

h2{
	@include font-size(2.6);
	margin-bottom: 20px;
	font-weight: 100;
	color: $color--blue-light;

	@include breakpoint(md) {
		@include font-size(3)
	}
}

h3 {
	@include font-size(1.8);
	margin-bottom: 10px;
	font-weight: 100;
}

h4 {
	@include font-size(1.6);
	margin-bottom: 10px;
	font-weight: 100;

}

h5 {
	@include font-size(1.6);
	margin-bottom: 10px;
}


// Link styles

a { 
	text-decoration: none;
	cursor: pointer;
	color: $color--blue-light;

	&:hover {
		text-decoration: underline;
	}
}

a:visited { 
}

a:hover { 
}

a:focus { 
	outline: thin dotted; 
}


// Standard text paragraphs

p {
	margin: 0 0 10px 0;
	padding: 0;
	line-height: 1.6;
	font-weight: 300;
	// @include font-size(1.6);
}


// List styles

ol {
	margin: 0 13px 8px 15px;
	padding: 0;
	list-style-position: inside;
}

ul {
	margin: 0;
	padding: 0;
	list-style-position: inside;
}

ol ol,
ol ul,
ul ul,
ul ol {
	margin-bottom: 2px;
}


.list-inline {
	list-style: none;
	margin: 0 0 15px 0;
	padding: 0;
	overflow: hidden;
	li {
		float:left;
		margin: 0 10px 0 0;
	}
}

dl {}

dt {
	font-weight:bold;
	margin-left:15px;
}

/*dl*/.dl-horizontal {

	dt {
		@include breakpoint(sm) {
			width: auto;
			float: left;
			overflow: hidden;
			clear: left;
			text-align: left;
			margin: 0 20px 0 0;
		}

	}

	dd {
		@include breakpoint(sm) {
			width: auto;
		}
	}

}

.list-unstyled * {
	list-style: none;
	list-style-image: none;
}


// Opinionated styling of typographic elements

hr { 
	display: block; 
	height: 1px; 
	border: 0; 
	border-top: 1px solid #ccc; 
	margin: 1em 0; 
	padding: 0; 
}

blockquote {
	border-left: 5px solid #eee;
	margin: 0;
	padding: 0 10px;
}

ins { 
	background: #ff9; 
	color: #000; 
	text-decoration: none; 
}

mark { 
	font-style: italic; 
	font-weight: bold; 
}

pre { 
	white-space: pre; 
	white-space: pre-wrap; 
	word-wrap: break-word; 
}
