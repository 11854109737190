.join-us-wrapper {
	background-color: $color--blue-light;
	padding: 30px 40px;
	height: 100%;
	margin-bottom: 30px;

	* {
		color: white;
	}

	@include breakpoint(md) {
		margin-right: 20px;
	}
}

.access-courses-wrapper, .mc-signup-form {
	
	height: 100%;

	.btn {
		display: block;
	}
	
	&.logged-in {
		text-align: center;
		justify-content: middle
	}

	a {
		color: $color--grey-dark;
		font-weight: 600;

		&.btn {
			color: white;

			&:hover {
				color: $color--blue-light;
			}

		}
	}

	form {
		width: 100%;
		height: 50px;
		display: flex;
		position: relative
	}

	input[type=text], input[type=email] {
		border: none;
		background-color: $color--grey-light;
		padding: 10px 20px;
		height: 50px;
		position: relative;
		outline: none;
		width: 100%;
		border-radius: 25px 0 0 25px;
	}

	input[type=submit] {
		border: none;
		background-size: 40%;
		color: white;
		border-radius: 0 25px 25px 0;
		padding: 10px 25px 10px 30px;
		height: 50px;
		float: right;
		outline: none;
	}

	.error {
		color: red;
		@include font-size(2)
	}
}

.access-courses-wrapper {
	padding: 30px 40px;
	background-color: white;
	
	input[type=submit] {
		background: $color--blue-light;
		background-image: url(../../images/tick.svg);
		background-repeat: no-repeat;
		background-position: 15px 12px;
		background-size: 35%;
	}

	img {
		width: 100%
	}
}


.playlist {
	li {
		list-style: none;
	}
}


.playlist-item {
	padding: 20px 0;
}

.playlist-item__thumb {
	height: 200px;
	cursor: pointer;
	position: relative;
	background-position: center;
	width: 100%;

	img {
		width: 60px;
		height: 60px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		opacity: .7;
		transition: all ease .3s;
	}

	&:hover {
		img {
			opacity: 1;
		}
	}

}

.playlist-item__title {
	padding: 10px 0;
}
