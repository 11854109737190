.link-section-title {
	font-weight: 400;
	color: $color--blue-light;
	text-align: center;
}

.links-section__link-wrapper {
	margin-bottom: 30px;
}

.links-section__link {
	min-height: 100px;
	position: relative;
	background-color: white;
	height: 100%;
	color: $color--grey-dark;
	display: block;

	&:hover{
		text-decoration: none;
		
		.links-section__image {
			transform: scale(1.2)
		}
	}

	@include breakpoint(sm) {
		display: flex;
		align-items: center;
	}
}

.links-section__image-wrapper {
	overflow: hidden;
	height: 150px;
	position: relative;

	@include breakpoint(sm) {
		position: absolute;
		height: 100%;
		width: 30%;	
	}
}

.links-section__image {
	height: 100%;
	width: 100%;
	transition: transform ease .4s;
	display: block;

	@include breakpoint(sm) {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}

.links-section__link-title {
	padding: 30px;
	text-align: center;
	@include font-size(2.4);
	margin-bottom: 0;

	@include breakpoint(sm) {
		width: 70%;
		margin-left: 30%;
		padding: 0 30px;
		text-align: left;
	}
}