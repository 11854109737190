/*------------------------------------*\
    MAIN SASS STYLES FILE
\*------------------------------------*/

// 1.	Utilities 
// 2.	Base 
// 3.	Layout
// 4.	Global
// 5.	ACF Flexible Content Components 
// 6.	Pages 
// 7.	Vendor


/*------------------------------------*\
    1.	UTILITIES
\*------------------------------------*/
@import "utilities/variables";
@import "utilities/mixins";


/*------------------------------------*\
    2.	BASE
\*------------------------------------*/
@import "base/normalize";
@import "base/base";
@import "base/helper";
@import "base/fonts";
@import "base/typography";
@import "base/buttons";
@import "base/forms";


/*------------------------------------*\
    3.	LAYOUT
\*------------------------------------*/
@import "layout/bootstrap-grid";


/*------------------------------------*\
    4.	GLOBAL
\*------------------------------------*/
@import "global/header";
@import "global/responsive-drop-off-nav";
@import "global/responsive-off-canvas-nav";
@import "global/footer";


/*----------------------------------------*\
    5.	ACF FLEXIBLE CONTENT COMPONENTS
\*----------------------------------------*/
// @import "flexible-content/background-image-with-text-card";
// @import "flexible-content/contact-details";
@import "flexible-content/ctas";
// @import "flexible-content/event-split";
// @import "flexible-content/events";
// @import "flexible-content/faqs";
// @import "flexible-content/flow-list";
// @import "flexible-content/fullwidth-image-text-split";
@import "flexible-content/hero";
// @import "flexible-content/icon-list";
@import "flexible-content/links-list";
// @import "flexible-content/review-block";
// @import "flexible-content/services";
// @import "flexible-content/side-by-side";
// @import "flexible-content/slider";
// @import "flexible-content/social-section";
@import "flexible-content/team-profiles";
@import "flexible-content/text-with-link";
// @import "flexible-content/three-col-img-btn";



/*------------------------------------*\
    6.	PAGES
\*------------------------------------*/
@import "pages/blog";
@import "pages/shop";
@import "pages/contact";
@import "pages/courses";

/*------------------------------------*\
   7.	VENDOR
\*------------------------------------*/
@import "vendor/slick";
@import "vendor/slick-theme";