/*------------------------------------*\
    BOOTSTRAP 3 GRID
\*------------------------------------*/


// - Bootstrap 3.1.1
// --------------------------------------------------

// See: http://getbootstrap.com/css/#grid
// For examples of mixin usage, see also: http://alademann.github.io/sass-bootstrap/css/#grid

// Grid System Variables
// ---------------------

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

//use Bootstrap variables but define them using our existing variables

$screen-xs-min: $xs-min;
$screen-sm-min: $sm-min;
$screen-md-min: $md-min;
$screen-lg-min: $lg-min;
$screen-xl-min: $xl-min;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         40px !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
//** For `$screen-sm-min` and up.
$container-sm: $screen-sm-min !default;

// Medium screen / desktop
//** For `$screen-md-min` and up.
$container-md: $screen-md-min !default;

// Large screen / wide desktop
//** For `$screen-lg-min` and up.
$container-lg: $screen-lg-min !default;

// Extra Large screen / wide desktop
//** For `$screen-lg-min` and up.
$container-xl: $screen-xl-min !default;


// Grid System Mixins
// ------------------

// Centered container element
@mixin container-fixed() {
	margin-right: auto;
	margin-left: auto;
	padding-left:  ($grid-gutter-width / 2);
	padding-right: ($grid-gutter-width / 2);
	@include clearfix();
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
	margin-left:  ($gutter / -2);
	margin-right: ($gutter / -2);
	@include clearfix();
}

// Generate the extra small columns
@mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	float: left;
	width: percentage(($columns / $grid-columns));
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);
}

@mixin make-xs-column-offset($columns) {
	@media (min-width: $screen-xs-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}

@mixin make-xs-column-push($columns) {
	@media (min-width: $screen-xs-min) {
		left: percentage(($columns / $grid-columns));
	}
}

@mixin make-xs-column-pull($columns) {
	@media (min-width: $screen-xs-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Generate the small columns
@mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);

	@media (min-width: $screen-sm-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}

@mixin make-sm-column-offset($columns) {
	@media (min-width: $screen-sm-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}

@mixin make-sm-column-push($columns) {
	@media (min-width: $screen-sm-min) {
		left: percentage(($columns / $grid-columns));
	}
}

@mixin make-sm-column-pull($columns) {
	@media (min-width: $screen-sm-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Generate the medium columns
@mixin make-md-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);

	@media (min-width: $screen-md-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}

@mixin make-md-column-offset($columns) {
	@media (min-width: $screen-md-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}

@mixin make-md-column-push($columns) {
	@media (min-width: $screen-md-min) {
		left: percentage(($columns / $grid-columns));
	}
}

@mixin make-md-column-pull($columns) {
	@media (min-width: $screen-md-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Generate the large columns
@mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);

	@media (min-width: $screen-lg-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}

@mixin make-lg-column-offset($columns) {
	@media (min-width: $screen-lg-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}

@mixin make-lg-column-push($columns) {
	@media (min-width: $screen-lg-min) {
		left: percentage(($columns / $grid-columns));
	}
}

@mixin make-lg-column-pull($columns) {
	@media (min-width: $screen-lg-min) {
		right: percentage(($columns / $grid-columns));
	}
}


// Generate the large columns
@mixin make-xl-column($columns, $gutter: $grid-gutter-width) {
	position: relative;
	min-height: 1px;
	padding-left:  ($gutter / 2);
	padding-right: ($gutter / 2);

	@media (min-width: $screen-xl-min) {
		float: left;
		width: percentage(($columns / $grid-columns));
	}
}

@mixin make-xl-column-offset($columns) {
	@media (min-width: $screen-xl-min) {
		margin-left: percentage(($columns / $grid-columns));
	}
}

@mixin make-xl-column-push($columns) {
	@media (min-width: $screen-xl-min) {
		left: percentage(($columns / $grid-columns));
	}
}

@mixin make-xl-column-pull($columns) {
	@media (min-width: $screen-xl-min) {
		right: percentage(($columns / $grid-columns));
	}
}



// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-grid-columns() {
		$list: '';
		$i: 1;
		$list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
		@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
	}

	#{$list} {
		position: relative;
		// Prevent columns from collapsing when empty
		min-height: 1px;
		// Inner gutter via padding
		padding-left:  ($grid-gutter-width / 2);
		padding-right: ($grid-gutter-width / 2);
	}
}


// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin float-grid-columns($class) {
	$list: '';
	$i: 1;
	$list: ".col-#{$class}-#{$i}";
	@for $i from (1 + 1) through $grid-columns {
		$list: "#{$list}, .col-#{$class}-#{$i}";
	}
	#{$list} {
		float: left;
	}
}

@mixin calc-grid-column($index, $class, $type) {

	@if ($type == width) and ($index > 0) {
		.col-#{$class}-#{$index} {
		  width: percentage(($index / $grid-columns));
		}
	}

	@if ($type == push) {
		.col-#{$class}-push-#{$index} {
		  left: percentage(($index / $grid-columns));
		}
	}

	@if ($type == pull) {
		.col-#{$class}-pull-#{$index} {
		  right: percentage(($index / $grid-columns));
		}
	}

	@if ($type == offset) {
		.col-#{$class}-offset-#{$index} {
		  margin-left: percentage(($index / $grid-columns));
		}
	}
}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin loop-grid-columns($columns, $class, $type) {
	@for $i from 0 through $columns {
		@include calc-grid-column($i, $class, $type);
	}
}


// Create grid for specific class
@mixin make-grid($class) {
	@include float-grid-columns($class);
	@include loop-grid-columns($grid-columns, $class, width);
	@include loop-grid-columns($grid-columns, $class, pull);
	@include loop-grid-columns($grid-columns, $class, push);
	@include loop-grid-columns($grid-columns, $class, offset);
}


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
	@include container-fixed();

	@media (min-width: $screen-sm-min) {
		width: $container-sm;
	}

	@media (min-width: $screen-md-min) {
		width: $container-md;
	}

	@media (min-width: $screen-lg-min) {
		width: $container-lg;
	}

	@media (min-width: $screen-xl-min) {
		width: $container-xl;
	}
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
	
	max-width: $breakpoint-xl;

	@include breakpoint(sm) {
		@include container-fixed();
	}
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
	@include make-row();
	position: relative;
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.


@media (min-width: $screen-xs-min) {
	@include make-grid(xs);
}


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: $screen-sm-min) {
	@include make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: $screen-md-min) {
	@include make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-lg-min) {
	@include make-grid(lg);
}


// XL grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: $screen-xl-min) {
	@include make-grid(xl);
}

