/*------------------------------------*\
	MIXINS
\*------------------------------------*/

//REM px font size
@mixin font-size($size: 1.4) {
	font-size: ($size * 10) + px;
	font-size: $size + rem;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

@mixin absolute-center() {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    transform: translateY(-50%) translateX(-50%);
    display: block;
}


// uses breakpoints defined in variables.scss
@mixin breakpoint($point, $value: 0) {

	@if $point == xs {
		@media screen and (min-width: $xs-min) { @content; }
	}

	@else if $point == sm {
		@media screen and (min-width: $sm-min) { @content; }
	}

	@else if $point == md {
		@media screen and (min-width: $md-min) { @content; }
	}

	@else if $point == lg {
		@media screen and (min-width: $lg-min) { @content; }
	}

	@else if $point == xl {
		@media screen and (min-width: $xl-min) { @content; }
	}

	@else {
		@media screen and ($point: $value) { @content; }
	}

}

@mixin between-breakpoints($min, $max) {
	@media screen and (min-width: $min) and (max-width: $max) {
		@content;
	}
}

