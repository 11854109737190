
.contact-item {
	margin-bottom: 10px;
	overflow: hidden;
	overflow-y: auto;

	p {
		margin-left: 50px;
		display: block;
	}
	
	p, a {
		color: $color--grey-dark;
		@include breakpoint(sm) {
			@include font-size(2);
			line-height: 1.5;
		}
	}
}

.icon-wrapper {
	display: inline-block;
	width: 34px;
	height: 34px;
	float: left;
	border: 1px solid $color--grey-dark;
	border-radius: 50%;

	svg {
		width: 20px;
		height: 20px;
		margin: 6px auto;
		display: block;
	}
}