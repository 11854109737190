/*------------------------------------*\
    BUTTONS
\*------------------------------------*/

.btn {
	padding: 10px 44px;
	display: inline-block;
	transition: all ease .2s;
	cursor: pointer;
	text-decoration: none;
	border-radius: 2em;
	font-weight: 400;
	background-color: $color--blue-light;
	border: 2px solid $color--blue-light;
	color: white;


	&:hover {
		background-color: white;
		color: $color--blue-light;
		text-decoration: none;
	}
}

.btn--link {
	padding: 0;
	text-decoration: none;

	&:hover {
		text-decoration: underline
	}
}

.btn--bordered {
	border: {
		width: 1px;
		color: white;
		style: solid;
	}

	color: white;
}



// THEME BUTTON STYLES

.btn--blue {
	background-color: $color--blue-light;
	color: white;
	border: 2px solid $color--blue-light;

	&:hover {
		background-color: transparent;
		color: $color--blue-light;
		border-color: $color--blue-light;
	}
}

.btn--dark-blue {
	background-color: $color--blue-dark;
	color: white;
	border: 2px solid $color--blue-dark;

	&:hover {
		background-color: white;
		color: $color--blue-dark;
		border-color: $color--blue-dark;
	}
}


