/*------------------------------------*\
    FORMS
\*------------------------------------*/

.form-row {
	width: 100%;
	margin-top: 20px;

	input:not([type=submit]), 
	textarea {
		width: 100%;
	}

	.wpcf7-form-control:not([type=submit]) {
		border: none;
		padding: 10px;
		margin: 10px 0 0;
		outline: none;
		min-height: 40px;
	}


	label {
		margin: 30px 0 0;
		display: block;
	}

	br {
		display: none;
		visibility: hidden;
	}

	input[type=submit] {
		@extend .btn;
		width: 100%;
		margin-top: 30px;

		@include breakpoint(sm) {
			float: right;
			width: auto;
		}
	}

	select {
		width: 100%;
		height: 40px;
		border-radius: 0 !important;
		-webkit-appearance: none;
		-moz-appearance: none;
		outline: none;

		@include breakpoint(md) {
			width: 50%;
		}
	}

	textarea {
		height: 100px;
	}
}

.wpcf7 {
	clear: left;
}
