.shop-item-wrapper {
	margin-bottom: 30px;
}

.shop-item {
	// padding: 20px;
	background-color: white;
	height: 100%;
}

.shop-item__photo-wrapper {
	overflow: hidden;
	height: 200px;
	position: relative;

	@include breakpoint(xs) {
		height: 250px;
		width: 100%;	
	}

	@include breakpoint(sm) {
		height: 350px;
		width: 100%;	
	}
}

.shop-item__photo {
	height: 100%;
	width: 100%;
	transition: transform ease .4s;

	@include breakpoint(sm) {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}
}

.shop-item__info {
	padding: 30px;

	@include breakpoint(sm) {
		padding: 40px;	
	}
}

.shop-item__price {
	@include font-size(2);
	margin-bottom: 20px;
}

.shop-item__title {
	@include font-size(2);
	font-weight: 600;
	margin-bottom: 0;
}