/*------------------------------------*\
    HEADER
\*------------------------------------*/

header {
	position: fixed;
	top: 0;
	padding: 15px 0;
	background-color: white;
	z-index: 20;
	width: 100%;
	height: 60px;

	@include breakpoint(sm) {
		height: 70px;
	}

	@include breakpoint(sm) {
		position: relative;
		top: auto;
		height: auto;
		padding: 40px 0 30px
	}
}

.header-logo {
	width: 100px;
	display: block;
	float: left;
	
	@include breakpoint(sm) {
		width: 200px;
	}
}

.header-contact {
	float: right;
	margin: 0 35px 0 0;
	display: inline-block;

	li  {
		margin-bottom: 0;
		display: inline-block;
		float: left;
		margin-left: 10px;

		&:last-child {
			@include breakpoint(md) {
				display: none;
			}
		}

		@include breakpoint(sm) {
			margin-left: 15px;
		}
	}

	a {
		text-decoration: none;
		display: block;
	}

	@include breakpoint(xs) {
		margin: 0 40px 0 0;
	}

	@include breakpoint(sm) {
		margin: 0 50px 0 0;
	}

	@include breakpoint(sm) {
		margin: 10px 0 0 0;
	}
}


.header-contact__tel, 
.header-contact__email,
.header-contact__book {
	width: 34px;
	height: 34px;
	background-size: cover;
	display: block;
	position: relative;
	border-radius: 50%;
	border: 2px solid $color--blue-dark;
	transition: all ease .3s;

	svg {
		width: 20px;
		height: 20px;
		content: '';
		position: absolute;
		left: 5px;
		top: 5px;
		transition: all ease .3s;

		* {
			transition: all ease .3s;
		}
	}

	&:hover {
		border-color: $color--blue-light;
	}

	@include breakpoint(sm) {
		width: 40px;
		height: 40px;

		svg {
			width: 22px;
			height: 22px;
			left: 7px;
		}
	}
}

.header-contact__tel {

	svg {
		width: 18px;
		height: 18px;
		top: 6px;
		left: 6px;
	}

	&:hover {
		path {
			fill: $color--blue-light
		}
	}

	@include breakpoint(sm) {
		svg {
			width: 20px;
			height: 20px;
			top: 8px;
			left: 8px;
		}
	}
}

.header-contact__email {
	
	&:hover {
		rect, polyline {
			fill: $color--blue-light
		}
	}

	@include breakpoint(sm) {
		svg {
			top: 7px;
		}
	}
}

.header-contact__book {
	background-color: $color--blue-light;
	border: 2px solid $color--blue-light;

	svg {
		top: 4px;
	}

	span {
		display: none;
	}

	&:hover {
		background-color: transparent;

		path, rect {
			fill: $color--blue-light
		}
	}

	@include breakpoint(xs) {
		
		padding: 8px 40px;
		border-radius: 2em;
		// font-weight: 00;
		width: auto;
		color: white;
		
		span {
			display: block;
			line-height: 1em;
		}

		svg {
			display: none
		}

		&:hover {
			color: $color--blue-light;
		}
	}

	@include breakpoint(sm) {
		margin-left: 15px;
		span {
			line-height: 1.4em;
		}
	}
}


.breadcrumbs {
	color: $color--blue-light;
	font-weight: 700;
	text-transform: uppercase;
	@include font-size(1.2);
	letter-spacing: 1px;

	* {
		color: white;
	}

	a {
		// font-weight: 400;
	}

	span {
		margin: 0 3px;
	}


	@include breakpoint(sm) {
		@include font-size(1.4);
	}
}

