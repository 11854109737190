// /*------------------------------------*\
//     RESPONSIVE DROP OFF NAV
// \*------------------------------------*/


.nav-wrapper {
	ul {
  		list-style: none;
  	}

  	a {
		padding: 15px;
		display: block;
		color: $color--grey-dark; 
		text-transform: uppercase;

		&:hover {
			text-decoration: none;
		}
	}
}

.desktop-nav-wrapper { 
	
	position: absolute;
	z-index: -30;
	opacity: 0;

	@include breakpoint(sm) {
		z-index: 30;
		background-color: white;
		border-top: 1px solid $color--grey-light;
		opacity: 1;
		position: relative;
	}

	.dropoff-nav {
		position: relative;
	  	display: flex;
	  	align-items: center;
	}

	.dropoff-nav__links {
		display: flex;
		justify-content: center;
		flex: 1;
		overflow: hidden;

		a {
			display: inline-block;
			float: left;
		}

		li {
			flex: none;

			&:hover {
				background-color: $color--blue-light;

				a {
					color: white;
				}

				.submenu-icon {
			  		&:before {
			  			background: {
				    		image: url('../../images/arrow.svg');
				    	}
			  		}
			  	}

	  			.sub-menu {
	  				display: block;
	  			}
	  		}
	  	}

	  	.sub-menu {
	  		display: none;
	  		background-color: $color--blue-light;
	  		min-width: 200px;

	  		a {
	  			color: white;
	  			display: block;
	  			float: none;

	  			&:hover {
	  				background-color: white;
	  				color: $color--blue-light
	  			}
	  		}

	  		@include breakpoint(sm) {
	  			position: absolute;
		  		top: 100%;
	  		}
	  	}

	  	.submenu-icon {
	  		display: inline-block;
	  		position: relative;
	  		width: 12px;
	  		height: 12px;
	  		top: 15px;
	  		left: -8px;

	  		&:before {
	  			background: {
		    		image: url('../../images/chevron.svg');
		    	}
	  		}
	  	}
	}

	.dropoff-nav__hidden-links {
		position: absolute;
		background-color: $color--blue-light;
		right: 0; 
		top: 100%;
		z-index: 20;

		a {
			color: white;
		}

		li {
			position: relative;

			&.menu-item-parent {
				> a {
					margin-right: 50px
				}
			}
		}

		.submenu-icon {
			top: 12px;
			right: 10px;
		}

		.sub-menu {
			display: block;
			position: relative;
			height: 0;
			visibility: hidden;

			&.is-open {
				height: auto;
				visibility: visible;
				
				transition: all ease .3s;
			}
		}

		&.hidden {
			display: none;
		}
		
	}

	.dropoff-nav__more {
		align-self: stretch;
		transition: all .4s ease-out;
		padding: 0 15px;
		outline: 0;
		border: 0;
		font-weight: bold;
		background-color: $color--blue-light;
		color: white;

		&.hidden {
			transition: none;
		  	width: 0;
		  	padding: 0;
		  	overflow: hidden;
		}
	}
}


.submenu-icon {
	width: 50px;
	height: 50px;
	cursor: pointer;

	@include breakpoint(sm) {
		width: 30px;
		height: 30px;
	}
	
	&:before {
    	content: '';
    	display: block;
    	position: absolute;
    	height: 12px;
    	width: 12px;
    	left: 0;
    	right: 0;
    	top: 0;
    	bottom: 0;
    	margin: auto;
    	transform: rotate(90deg);
    	transform-origin: 50% 50%;
    	transition: transform ease .3s;

    	background: {
    		size: contain;
    		repeat: no-repeat;
    	}
    }
    
    &.is-open {
		&:before {
	    	transform: rotate(-90deg);
	    }
	}
}


.nav-icon {
	position: fixed;
    text-align: center;
    cursor: pointer;
    transition: all ease .3s;
    right: 0;
    top: 0;
    height: 60px;
    width: 60px;
    z-index: 20;
    background-color: $color--blue-light;
	
	span {
		width: 30px;
		height: 2px;
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 50%;
		transition: all ease .3s;
		background-color: white;
		
		&:first-child {
			top: 10px;
		}
		
		&:last-child {
			bottom: 21px;
		}
	}

	&.is-open {
		span {
			&:nth-child(2) {
				width: 0;
				opacity: 0;
			}
			
			&:first-child {
				transform: rotate(45deg) translateY(7px) translateX(7px);
			}
			
			&:last-child {
				transform: rotate(-45deg) translateY(-6px) translateX(5px);
			}
		}
	}
	
	@include breakpoint(sm) {
		display: none;
	}
}

.mobile-nav-wrapper {
	height: 100%;
	position: fixed;
	left: 0;
	z-index: 15;
	background-color: $color--blue-light;
	width: 100%;
	transform: translateX(100%);
	transition: transform ease .3s;
	padding-bottom: 70px;
	overflow: auto;

	&.is-open {
		transform: translateX(0);
	}

	@include breakpoint(sm) {
		display: none
	}
	
	ul {
		list-style: none
	}

	li {
		width: 100%;
		position: relative;
	}

	a {
		padding: 15px 25px;
		color: white;
		display: block;
		border-bottom: 1px solid $color--blue-lightest;
		font-weight: 300;
	}

	.sub-menu {
		display: block;
		position: relative;
		height: 0;
		visibility: hidden;
		background-color: $color--blue-mid;

		&.is-open {
			height: auto;
			visibility: visible;
			transition: all ease .3s;
		}
	}

	.submenu-icon {
		background-color: $color--blue-mid;
		display: block;
		position: absolute;
		right: 0;
		top: 0;


		&:before {
			background: {
	    		image: url('../../images/arrow.svg');
	    	}
		}
		
	}

	.dropoff-nav__more {
		display: none;
	}
}

.mobile-nav {
	margin-top: 80px;
	list-style: none;

	li {
		&:last-child {
			border: none;
		}
	}

	a {
		color: white;
		text-decoration: none;
		@include font-size(1.6);
		text-transform: uppercase;
		padding: 15px 20px;
		display: block;
	}
}