/*------------------------------------*\
    VARIABLES
\*------------------------------------*/


// 1.	Typography 
// 2.	Responsive Breakpoints 
// 3.	Colours 
// 4.	Z-Index 


/*------------------------------------*\
   1.	TYPOGRAPHY
\*------------------------------------*/

$brand-font-1: 'American Typewriter Semibold', serif;
$brand-font-2: "Roboto", sans-serif;

$base-font-size:   62.5%;
$base-line-height: 1.6; 

$body-font-desktop: 1.6; // = 16px
$body-font-mobile:  1.6; // = 16px


/*------------------------------------*\
   2.	RESPONSIVE BREAKPOINTS
\*------------------------------------*/

$breakpoint-xs: 540px;
$breakpoint-sm: 768px;
$breakpoint-md: 960px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1300px;

$nav-breakpoint: $breakpoint-sm;

$xs-min: $breakpoint-xs;
$xs-max: ($breakpoint-sm - 1);

$sm-min: $breakpoint-sm;
$sm-max: ($breakpoint-md - 1);

$md-min: $breakpoint-md;
$md-max: ($breakpoint-lg - 1);

$lg-min: $breakpoint-lg;
$lg-max: ($breakpoint-xl - 1);

$xl-min: $breakpoint-xl;


/*------------------------------------*\
   COLOURS
\*------------------------------------*/

$color--grey-dark: #32343F;
$color--grey-mid: #c7c7c7;
$color--grey-light: #F2F2F2;

$color--blue-dark: #32343F;
$color--blue-mid: #0A6EA5;
$color--blue-light: #0A8FEA;
$color--blue-lightest: #4CAEE5;

$color--nav: $color--blue-dark;


/*------------------------------------*\
   Z-INDEX
\*------------------------------------*/
$z-100: 100;
$z-200: 200;
$z-300: 300;
$z-400: 400;
$z-500: 500;
$z-600: 600;
$z-700: 700;
$z-800: 800;
$z-900: 900;
$z-1000: 1000;
